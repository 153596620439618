import { Col, Row } from "components/shared/styles/Layout"
import { ReactNode } from "react"

interface Col2Props {
  children: [ReactNode, ReactNode]
}

export const Col2 = ({ children: [left, right] }: Col2Props): JSX.Element => {
  return (
    <Row>
      <Col width={1 / 2}>{left}</Col>
      <Col width={1 / 2}>{right}</Col>
    </Row>
  )
}
