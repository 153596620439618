import { VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { ContentWrapper, Section } from "components/shared/styles/Layout"
import { Col2 } from "components/ui/Col2/Col2"
import { Layout } from "components/ui/Layout/Layout"
import { PageHero } from "components/ui/PageHero/PageHero"
import { GatsbyImage } from "gatsby-plugin-image"
import { Paragraph, Title1, Title3 } from "lib/ui/Typography/Typography"
import { ContentfulImage } from "types/contentful"

interface AboutViewProps {
  images: {
    filip: ContentfulImage
    hero: ContentfulImage
    linnea: ContentfulImage
    robert: ContentfulImage
    karin: ContentfulImage
    anders: ContentfulImage
    lorne: ContentfulImage
    ted: ContentfulImage
    marek: ContentfulImage
  }
}

export const AboutView = ({ images }: AboutViewProps): JSX.Element => {
  return (
    <Layout>
      <PageHero image={images.hero.media.gatsbyImageData}>
        <Title1>Making Earth a better place</Title1>
      </PageHero>
      <ContentWrapper>
        <Col2>
          <Title1 as="h2">Manifesto</Title1>
          <Paragraph>
            Climate change is the most important challenge we face today. Humanity has an endless
            capacity for creativity, but in many areas we remain resistant to change. The road
            freight industry accounts for 7 percent of global CO2 emissions, consuming over 5
            million barrels of oil per year. Einride was founded in 2016 as a dream, one based on
            the conviction that the age of autonomy and electrification gives us the opportunity to
            create a more desirable version of the future, one that is aligned with human-centric
            values and the ecological necessities of our planet. With Autonomous Electric Transport
            (AET) and an intelligent freight mobility platform, we can change road freight for good.
          </Paragraph>
        </Col2>
      </ContentWrapper>
      <VerticalSpacing size="xl" />
      <ContentWrapper>
        <Col2>
          <Title1 as="h2">What we do</Title1>
          <div>
            <Title3>Digitalization</Title3>
            <Paragraph>
              Without an intelligent, interconnected ecosystem working behind the scenes to optimize
              the transition, switching to an electric and autonomous fleet will be nearly
              impossible. Einride Saga allows shippers and carriers to offer faster, greener, and
              cheaper freight. Through digitalization we are able to deliver better experiences and
              build sustainable relationships for our customers.
            </Paragraph>
            <VerticalSpacing />
            <Title3>Electrification</Title3>
            <Paragraph>
              By substituting electricity for diesel freight, associated CO2 emissions can be
              reduced by up to 90% at the source. We can also eliminate NOx and other air pollutants
              that are harmful to public health, especially in condensed urban areas. As an added
              bonus, electric transport results in an overall cost reduction when implemented and
              coordinated at scale by our platform.
            </Paragraph>
            <VerticalSpacing />
            <Title3>Automation</Title3>
            <Paragraph>
              Autonomous vehicles are the future of transportation. Einride&rsquo;s autonomous
              vehicle currently operates on public roads and at customer sites in Sweden with remote
              oversight and drive capability. To manage the transition to full autonomy, Einride
              developed a proprietary five-step framework. Level 1 and 2 require limited regulatory
              oversight and represent highly controllable environments such as fenced facilities or
              nearby deliveries on public roads, while level 5 represents dense and complex urban
              environments.
            </Paragraph>
          </div>
        </Col2>
      </ContentWrapper>
      <VerticalSpacing size="xl" />
      <Section>
        <ContentWrapper>
          <Title1 as="h2">Founders</Title1>
          <VerticalSpacing size="md" />
          <PeopleGrid>
            <div>
              <GatsbyImage
                alt={images.robert.altText}
                image={images.robert.media.gatsbyImageData}
              />
              <PersonName>Robert Falck</PersonName>
              <PersonTitle>CEO and Founder</PersonTitle>
              <PersonDescription>
                A serial entrepreneur, Robert Falck founded and co-founded several businesses and
                worked his way up from the factory floor of major OEMs before starting Einride in
                2016. He holds a B.Sc and M.Sc. in Mechanical Engineering, a B.Sc. in Economics and
                Finance, and a B.Sc. in Business Administration.
              </PersonDescription>
            </div>
            <div>
              <GatsbyImage
                alt={images.linnea.altText}
                image={images.linnea.media.gatsbyImageData}
              />
              <PersonName>Linnéa Kornehed Falck</PersonName>
              <PersonTitle>Deputy CEO and Founder</PersonTitle>
              <PersonDescription>
                As Deputy CEO, Founder and a member of the board, Linnéa Kornehed Falck has taken
                Einride from a small startup to a household name in digital, electric, and
                autonomous transportation, with Fortune 500 companies as customers and attracting
                worldwide media attention. Linnéa is a member of the World Economic Forum&apos;s
                tech innovators community, a Forbes&apos; 30 Under 30 All-Star Alumni, and has been
                awarded the Les Margaret Award and Future Female Leader for her innovative vision
                and leadership. Before starting Einride, she worked in product development and was
                an IT consultant for several startups.
              </PersonDescription>
            </div>
            <div>
              <GatsbyImage alt={images.filip.altText} image={images.filip.media.gatsbyImageData} />
              <PersonName>Filip Lilja</PersonName>
              <PersonTitle>VP Special Projects and Founder</PersonTitle>
              <PersonDescription>
                A passionate business developer and builder of high-performing teams, Filip Lilja is
                VP of Special Projects and Founder at Einride. He has a wide range of experience in
                several fields, from IT consultancy to sonar operation in the Swedish Armed Forces.
              </PersonDescription>
            </div>
          </PeopleGrid>
        </ContentWrapper>
        <VerticalSpacing size="xl" />
        <ContentWrapper>
          <Title1 as="h2">Board of directors</Title1>
          <VerticalSpacing size="md" />
          <PeopleGrid>
            <div>
              <GatsbyImage alt={images.karin.altText} image={images.karin.media.gatsbyImageData} />
              <PersonName>Karin Markides</PersonName>
              <PersonTitle>Board Member</PersonTitle>
              <PersonDescription>
                President at American University of Armenia, Board Chair Technical University of
                Denmark
              </PersonDescription>
            </div>
            <div>
              <GatsbyImage
                alt={images.anders.altText}
                image={images.anders.media.gatsbyImageData}
              />
              <PersonName>Anders Böös</PersonName>
              <PersonTitle>Chairman</PersonTitle>
              <PersonDescription>
                Hantverksdata AB and Valamis OY, non-executive director Investment AB Latour and
                tbd30 AB
              </PersonDescription>
            </div>
            <div>
              <GatsbyImage alt={images.lorne.altText} image={images.lorne.media.gatsbyImageData} />
              <PersonName>Lorne Abony</PersonName>
              <PersonTitle>Board Member</PersonTitle>
              <PersonDescription>
                CEO FastForward Innovations, Chairman of the Board Amy
              </PersonDescription>
            </div>
            <div>
              <GatsbyImage
                alt={images.linnea.altText}
                image={images.linnea.media.gatsbyImageData}
              />
              <PersonName>Linnéa Kornehed Falck</PersonName>
              <PersonTitle>Board Member</PersonTitle>
              <PersonDescription>Deputy CEO and Founder at Einride</PersonDescription>
            </div>
            <div>
              <GatsbyImage alt={images.ted.altText} image={images.ted.media.gatsbyImageData} />
              <PersonName>Ted Persson</PersonName>
              <PersonTitle>Board Member</PersonTitle>
              <PersonDescription>Partner EQT Ventures</PersonDescription>
            </div>
            <div>
              <GatsbyImage alt={images.marek.altText} image={images.marek.media.gatsbyImageData} />
              <PersonName>Marek Kiisa</PersonName>
              <PersonTitle>Board Member</PersonTitle>
              <PersonDescription>Managing Partner Nordic Ninja VC</PersonDescription>
            </div>
          </PeopleGrid>
        </ContentWrapper>
      </Section>
    </Layout>
  )
}

const PeopleGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media ${({ theme }) => theme.mediaQueries.md} {
    margin: 0 -16px;
  }
  > div {
    width: 100%;
    margin: 0 0 32px 0;
    @media ${({ theme }) => theme.mediaQueries.md} {
      width: calc(50% - 32px);
      margin: 0 16px 32px 16px;
    }
    @media screen and (min-width: 750px) {
      width: calc(33.3% - 32px);
    }
    @media ${({ theme }) => theme.mediaQueries.lg} {
      width: calc(25% - 32px);
    }
  }
`

const PersonName = styled(Title3)`
  font-size: 18px;
`

const PersonTitle = styled(Paragraph)`
  font-size: 14px;
`
const PersonDescription = styled(PersonTitle)``
