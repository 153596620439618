import { VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { ContentWrapper, Desktop, HeadingWrapper, Mobile } from "components/shared/styles/Layout"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { ReactNode } from "react"

const ImageWrapper = styled.div`
  margin-bottom: 20px;
`

interface PageHeroProps {
  image: IGatsbyImageData
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mobileStyle?: any
  children?: ReactNode
}

export const PageHero = ({ image, mobileStyle, children }: PageHeroProps): JSX.Element => {
  return (
    <>
      <VerticalSpacing size="lg" />
      <ContentWrapper>
        <HeadingWrapper>{children}</HeadingWrapper>
      </ContentWrapper>
      {image && (
        <ImageWrapper>
          <Desktop>
            <GatsbyImage image={image} alt="" />
          </Desktop>
          <Mobile>
            <GatsbyImage image={image} alt="" imgStyle={mobileStyle} />
          </Mobile>
        </ImageWrapper>
      )}
    </>
  )
}
