import { WindowLocation } from "@reach/router"
import { Seo } from "components/fragments/Seo/Seo"
import { AboutView } from "components/views/AboutView/AboutView"
import { graphql } from "gatsby"
import { ContentfulImage } from "types/contentful"

interface AboutPageProps {
  data: {
    filip: ContentfulImage
    hero: ContentfulImage
    linnea: ContentfulImage
    robert: ContentfulImage
    karin: ContentfulImage
    anders: ContentfulImage
    lorne: ContentfulImage
    ted: ContentfulImage
    marek: ContentfulImage
  }
  location: WindowLocation
}

const AboutPage = ({ data, location }: AboutPageProps): JSX.Element => {
  return (
    <>
      <Seo
        pathname={location.pathname}
        title="About Einride"
        description="Making earth a better place. While trucking accounts for 7% of global emissions, Einride digitizes, electrify, and automates shipping, making it sustainable."
      />
      <AboutView images={data} />
    </>
  )
}

export const query = graphql`
  query {
    filip: contentfulImage(contentful_id: { eq: "4OkIFgCIDbXdPuHVn5DRFn" }) {
      media {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      altText
    }
    hero: contentfulImage(contentful_id: { eq: "6W6cXFmSDyhEC2XfgyePYH" }) {
      media {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    linnea: contentfulImage(contentful_id: { eq: "3ftQNA2GRw4DjiUdUejkPr" }) {
      media {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      altText
    }
    robert: contentfulImage(contentful_id: { eq: "2BF7kjI1qXtXkEtENoxh7T" }) {
      media {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      altText
    }
    karin: contentfulImage(contentful_id: { eq: "348Cd1aFj6HQbBBaN1na2a" }) {
      media {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      altText
    }
    anders: contentfulImage(contentful_id: { eq: "62yfMtVmY7fXCs1vnDZok2" }) {
      media {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      altText
    }
    lorne: contentfulImage(contentful_id: { eq: "2Hnfuv0s9ce0GXDEXCAPxM" }) {
      media {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      altText
    }
    ted: contentfulImage(contentful_id: { eq: "bXvz2TrhHKQMX8OgRg5lw" }) {
      media {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      altText
    }
    marek: contentfulImage(contentful_id: { eq: "7FRkWRkFVA3F4YAbSKR5rM" }) {
      media {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      altText
    }
  }
`

export default AboutPage
